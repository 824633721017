@import "../variables";
@import "../template/mixin_templates";
@import "../../foundation/functions";

/* Général */
body {
  background-color: $body_background_color;
  color: $texte_color;
}

.content_p, body .wrapper.accueil_diaporama_template {
  background-color: $content_background_color;
}

.header_p {
  background-color: $header_background;
}

h2:not(.brand), .h2:not(.brand), h1:not(.fiche-titre-produit), .h1:not(.fiche-titre-produit),
.single-article .titre-article h1, .single-article .titre-article .h1, .apercu-article h2.titre-article, .apercu-article .h2.titre-article {
  color: $gros_titre_color;
  background: $gros_titre_background_color;
  a {
    color: inherit !important;
  }
}

a {
  color: $texte_lien;
  &:hover {
    color: $texte_lien_hover;
  }
}

.bouton, .button, input[type="submit"], .close-menu.ouvert,
body .template_fiche_produit_3 #fiche-produit .wrap-description .side-tabs a,
body .banner_diaporama .bx-wrapper .bx-next, body .banner_diaporama .bx-wrapper .bx-prev {
  border-color: $button_border;
  background-color: $button_background;
  color: $button_color;
  background-image: none !important;
  &:hover {
    background-color: $button_background_hover;
    color: $button_color_hover;
    border-color: $button_border_hover;
    &::before, &::after {
      color: $button_color_hover;
    }
  }
}

body .produits-accueil.template_boutique_accueil.accueil_boutique_template_1 .bx-wrapper .bx-controls-direction a.bx-next,
body .produits-accueil.template_boutique_accueil.accueil_boutique_template_1 .bx-wrapper .bx-controls-direction a.bx-prev,
.bx-controls-direction a.bx-next, .bx-controls-direction a.bx-prev {
  background: $arrow_background;
  border-color: $arrow_color;
  color: $arrow_color;

  &:hover {
    background: $texte_blanc;
    color: $couleur_principale;
  }
}

.close-menu.ferme {
  background-color: $button_background_hover;
  color: $button_color_hover;
  border-color: $button_border_hover;
  &::before, &::after {
    color: $button_color_hover;
  }
}

.hover .button_galerie {
  background-color: $arrow_background_hover;
  border-color: $arrow_background_hover;
}

/* site en deux colonnes */
.sidebar {
  border: 1px solid $colonne_gauche_border;
}

/* Header */

header {
  background-color: $header_background;
}

.slogan {
  color: $slogan_color_texte;
}

/* fil d'ariane */

#bloc-fil-title {
  background: #f8f8f8;
}

nav.arianne ul li {

  a {
    color: #444;

    &:hover {
      color: $couleur_principale;
    }

  }

  &::before {
    color: #444;
  }

  &:last-child {

    a {
      color: #888;

      &:hover {
        color: $couleur_principale;
      }
    }
  }

}

/* Menu */
.navigation_p {
  background: $menu_background_color;
}

.menu-secondaire .menu {
  background: transparent !important;
}

#nav-principal, #nav-secondary {
  .navigation_link_p {
    color: $nav_texte_color;
    &::after {
      border-left-color: $icone_background_color_hover;
    }
  }
  .navigation_button_p {
    background-color: $li_background_color;
    border-color: $li_border_color;
    &.actif, &:hover, &.sousmenu_ouvert {
      background-color: $li_background_color_hover;
      > .navigation_link_p {
        color: $nav_texte_color_hover;
      }
    }
  }
  .sous_navigation_link_p {
    color: $nav_texte_color;
  }
  .sous_navigation_button_p {
    background-color: $li_background_sous_color;
    &.actif, &:hover, &.sousmenu_ouvert {
      background-color: $li_background_color_sous_hover;
      > .sous_navigation_link_p {
        color: $nav_texte_color_sous_hover;
      }
    }
  }
  ul ul {
    border-top-color: $nav_texte_color_sous_hover;
  }
}

/* Produits */

.wrapper .produits-accueil.template_boutique_accueil .produits.block_unique_product:hover .mask, #wrapper .template_boutique .produits.block_unique_product:hover .mask, #wrapper .template_boutique .mode-catalogue .produits.block_unique_product:hover .mask {
  background-color: $couleur_secondaire;
}

.produits-accueil.template_boutique_accueil.accueil_boutique_template_1 .produits .nomprod, .produits-accueil.template_boutique_accueil.accueil_boutique_template_2 .produits .nomprod {
  background: transparent;

  a {
    color: $couleur_principale;
  }
}

.produits {
  background: $background_produit;
  color: $texte_produits;
  &:hover {
    background: $background_produit_hover;
  }
  .nomprod a {
    color: $texte_titre_produits;
  }
  .remise {
    background: $remise_background_color;
    color: $remise_texte_color;
  }
  .prix {
    border-color: $remise_border_color !important;
  }
}

.template_fiche_produit_1 #fiche-produit .top_fiche .wrap-description .ajouter-panier .triangle-ficheprod {
  border-right-color: $triangle_border_right;
  background: $remise_background_color;
}

/* Fiche produit */

#fiche-produit .triangle-ficheprod .remise-produit {
  background: $remise_background_color;
  color: $remise_texte_color;
}

.produits .mask .zoom, .productImageWrap > .zoom, #fiche-produit .template_boutique .produits .productImageWrap > .zoom, .template_boutique .produits .productImageWrap > .zoom {
  background-color: $couleur_secondaire;
  color: $color_addbasket;
  border-color: $couleur_secondaire;

  &::before, &::after {
    color: #fff;
  }

  &:hover {
    color: $color_addbasket_hover;

    &::before, &::after {
      color: #fff;
    }
  }
}

.addbasket {
  background-color: $button_background;
  color: $button_color;
  border-color: $button_border;

  &::before, &::after {
    color: $button_color;
  }

  &:hover {
    background-color: $button_background_hover;
    color: $button_color_hover;
    border-color: $button_border_hover;

    &::before, &::after {
      color: $button_color_hover;
    }
  }
}

.produit_etiquette, .produit_etiquette2, .produit_etiquette3 {
  background: $etiquette_produit_background;
  h3, .h3 {
    color: $etiquette_produit_texte;
    background: $etiquette_produit_background;
  }
}

.produit_etiquette::before {
  background-color: $etiquette_produit_background;
  color: $etiquette_produit_texte;
}

#fiche-produit {

  .block-fiche-titre {
    background-color: $background_titre_produit;
    &, .h1, h1 {
      color: $color_titre_produit;
    }
  }
  .block-fiche-brand {
    background: $gros_titre_background_color;
    color: $color_titre_produit;
    a, a:focus, a:hover {
      color: $color_titre_produit;
    }
  }

  .top_fiche {
    border-color: $fiche_produit_border;
    .prix {
      color: $couleur_prixprod;
      border-color: $remise_border_color !important;
    }
    .previews .previous, .previews .next {
      background: $arrow_background;
      border-color: $texte_blanc;
      color: $arrow_color;
      &:hover {
        background: $arrow_background_hover;
        color: $arrow_color_hover;
      }
    }

    .wrap-description .details-ficheprod a.button.btn-details {
      color: $couleur_principale;
    }

    .declinaison-attribute-group select, .product_options-group select {
      /*border-color: $couleur_secondaire;*/
    }

  }

  .bloc-quantite {

    background: transparent;

    input, button {
      background-color: $content_background_color;
      color: #000;
    }
  }

  .radio_unique {
    background: transparent;
    color: $declinaisons_boutons_color;
    border-color: $declinaisons_boutons_color;

    &:hover, &.radio-checked {
      background: $declinaisons_boutons_color;
      color: $declinaisons_boutons_color_hover !important;
    }
  }

  .wrap-description {
    .declinaison {
      background: transparent;
      color: $fiche_produit_text_color;
    }
  }
  .ajouter-panier {
    background-color: transparent;
  }


}

.note-produit {
  color: $declinaisons_boutons_color;
}

.wrap-details.bloc-description-detaille, .wrap-details.bloc-suggestions, .wrap-details.bloc-avis-clients {

  > h4, > .h4 {
    background: $gros_titre_background_color;

    span {
      background: $background_titre_produit;
      color: $color_titre_produit;
    }

  }

}

.template_fiche_produit_1 #fiche-produit .avec-declinaison .declinaison {
  border: $fiche_produit_border;
}

.template_fiche_produit_2 #fiche-produit {
  .wrap-description {
    .ajouter-panier .prix .triangle-ficheprod-second .remise-produit {
      background: $remise_background_color;
    }
  }
}

.template_fiche_produit_3 #fiche-produit {
  .top_fiche {

    .prix {

      .oldprix {
        color: $texte_gris;
      }

      .triangle-ficheprod-second .remise-produit {

        background: $couleur_secondaire;
        color: $texte_blanc;
      }

    }

  }
}

/* Galerie photo */

.galerie {
  background: $mask_background;
  .mask {
    background: $mask_background;
  }
  .addcommentaires, .commentP {
    color: $galerie_texte_color;
  }
  .bloc-titre-galerie p, .commentP p, .bloc-cache-overflow p {
    color: $galerie_texte_color;
    &::before {
      background-color: $galerie_texte_color;
    }
  }
}

/* Livre d'or */

.template_livreor {
  h4, .h4, .note {
    color: $message_lo_color;
  }
}

.template_livreor_1 .message_lo {
  border-color: $message_lo_color;

}

body .template_livreor_2 .list-messages .message_lo {
  .villePaysLo {
    color: $message_lo_color;
  }
  .IDLO {
    border-left-color: $message_lo_color;
  }
}

.template_livreor_3 .message_lo {
  .message::before, .message::after {
    color: $message_lo_color;
  }
}

/* Blog / Boutons */

.pagination {
  > li > a {
    background-color: $btn_texte_color;
    color: $btn_background_color;
  }
  li.active a {
    background-color: $btn_background_color;
    border-color: $btn_background_color;
    color: $btn_texte_color;
  }
}

/* Slider */

body .accueil_diaporama_template {

  .slide .slide-text .content-slide-text {
    p {
      background: $background_texte !important;
      color: $color_texte_slider !important;
    }

    a.addcommentaires {
      background: transparent;
      color: $texte_blanc;
      /*&:hover {
          background: transparent;
          color: $couleur_principale;
      }*/
    }

    a.slider-see-more {
      background: transparent;
      color: $texte_blanc;
      border-color: $texte_blanc;

      &:hover {
        background: $couleur_secondaire;
        border-color: $couleur_secondaire;
      }
    }
  }

  .bx-wrapper .bx-controls-direction a {
    background: $arrow_background;
    border-color: $texte_blanc;

    &::before {
      color: $arrow_color;
    }

    &:hover {
      background: $arrow_background_hover;
      border-color: $arrow_background_hover;

      &::before {
        color: $couleur_principale;
      }
    }
  }
}

/* Footer */

footer {
  background-color: $footer_background_color;
  color: $footer_texte_color;
  p {
    color: $footer_texte_color;
  }
  a {
    color: $footer_link_color;
  }
}

.fleche_top_site {
  background-color: $footer_background_color;
}

/* Responsive */

@media #{$medium-and-down} {
  #nav-principal > ul ul li {
    background: $li_background_color_hover;
    a:not(.menu_burger) {
      color: $nav_texte_color_hover;
    }
  }
  .menu_burger {
    background: $burger_background_color;
    color: $burger_texte_color;
  }
  #nav-principal.nav_fermee .menu_burger {
    background: $burger_background_color_ouvert;
    color: $burger_texte_color_ouvert;
  }
  .dropDown {
    color: inherit;
  }
  li:hover, li.actif {
    > .dropDown {
      color: inherit;
    }
  }

  .wrapper.accueil_diaporama_template .slide .button_slider {

    border-color: $texte_blanc;
    background: $arrow_background;
    color: $texte_blanc;

  }

  .wrapper.accueil_diaporama_template .slide .button_slider.slide-ouvert {
    border-color: $texte_blanc;
    background: $arrow_background_mobile;
    color: $texte_blanc;
  }
}

/* Template Panier */

.template-panier {
  background-color: $panier-background-color;
  color: $panier-titre-color;
  .connexion p {
    border-color: $panier-titre-color;
    &, a {
      color: $panier-titre-color;
    }
  }
  #header-panier {
    color: $panier-titre-color;
  }
  #panier #paniercontent {
    background-color: $panier-content-background-color;
    &, #paniertable {
      color: $panier-texte-color;
    }
    border-color: $panier-background-color;
  }
  &.template-panier-1 {
    #panier #paniercontent {
      &, #paniertable {
        color: $panier-titre-color;
      }
    }
  }
  .count-live {
    background: $panier-content-background-color;
    color: $panier-texte-color;
  }
}

.button.button-secondary {
  background: orange;

  &:hover {
    background: #444;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
  background: $li_background_color;
  border-color: $li_background_color;

  a {
    color: #fff;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
  border-color: $li_background_color;
  color: $li_background_color;

  a, span {
    color: $li_background_color;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
  color: #000;
}