$couleur_principale : #0a0a0a;
$couleur_secondaire: #ef886b;
$couleur_tierce: #888d90;
$couleur_bordures:#ebebeb;
$texte_blanc: #fff;
$texte_gris: #ccc;

/* Général */
$body_background_color : #fff;
$content_background_color : #fff;
$texte_lien:$couleur_secondaire;
$texte_lien_hover:lighten($texte_lien,10%);
$header_background:$content_background_color;
$slogan_color_texte:$couleur_secondaire;
$gros_titre_color:$couleur_secondaire;
$gros_titre_background_color:$couleur_principale;
$titre_color:#000;
$titre_border_color:$couleur_secondaire;
$texte_color:#000;
$button_background: $couleur_secondaire;
$button_border: $couleur_secondaire;
$button_color:#fff;
$button_background_hover: transparent;
$button_color_hover: $couleur_secondaire;
$button_border_hover: $couleur_secondaire;

/* Menu */
$menu_background_color:$couleur_principale;
$nav_background_color:$couleur_principale;
$nav_texte_color:#fff;
$nav_texte_color_hover:$couleur_secondaire;
$nav_texte_color_sous: $couleur_secondaire;
$nav_texte_color_sous_hover:$couleur_secondaire;
$li_background_color: $couleur_principale;
$li_background_sous_color: $couleur_principale;
$li_background_color_hover:$couleur_principale;
$li_background_color_sous_hover:$couleur_principale;
$icone_color:#fff;
$icone_color_hover:#fff;
$icone_background_color:#94999c;
$icone_background_color_hover:$couleur_secondaire;
$li_border_color:$couleur_bordures;

/* Menu responsive */
$burger_background_color:#94999c;
$burger_texte_color:#fff;
$burger_background_color_ouvert:$couleur_secondaire;
$burger_texte_color_ouvert:#fff;

/* Produits */
$mask_background: $couleur_secondaire;
$etiquette_produit_background: $couleur_secondaire;
$etiquette_produit_texte: #fff;
$remise_background_color:$couleur_secondaire;
$remise_texte_color:#fff;
$remise_border_color:$couleur_secondaire;
$background_produit: $content_background_color;
$background_produit_hover:$background_produit;
$border_produit: $couleur_secondaire;
$background_addbasket:$couleur_secondaire;
$color_addbasket:$texte_blanc;
$background_addbasket_hover:$couleur_secondaire;
$color_addbasket_hover:$couleur_secondaire;
$texte_produits:#000;
$texte_titre_produits: #000;
$triangle_border_right:#fff;
$couleur_prixprod: $couleur_principale;

/* Template Panier */
$panier-background-color:$couleur_secondaire;
$panier-titre-color:#000;
$panier-content-background-color:#fff;
$panier-texte-color:#000;

/* Fiche produit */
$fiche_produit_border:$couleur_bordures;
$background_achat:#fff;
$background_declinaison: rgba(0,0,0,0.1);
$fiche_produit_text_color: #000;
$declinaisons_boutons_color: $couleur_secondaire;
$declinaisons_boutons_color_hover: $texte_blanc;
$background_titre_produit: $couleur_secondaire;
$color_titre_produit: $texte_blanc;

/* Slider */
$background_texte: transparent;
$color_texte_slider:#fff;
$arrow_background: rgba(0,0,0,0.3);
$arrow_background_mobile: rgba(0,0,0,0.8);
$arrow_background_hover: $texte_blanc;
$arrow_color:#fff;
$arrow_color_hover: $couleur_principale;

/* Galerie photo*/
$galerie_texte_color:#fff;

/* Livre d'or */
$message_lo_color:$couleur_secondaire;

/* Blog / Boutons */
$btn_background_color:$couleur_principale;
$btn_texte_color:#000;

/* Footer */
$footer_background_color: $couleur_principale;
$footer_texte_color:#fff;
$footer_link_color:$texte_lien;

/* Colonne de gauche */
$colonne_gauche_border:$couleur_principale;

/* Flux panier */
$flux-panier_color-shadow:black;

@import "design/808series/declinaison";